import queryStringInstance from 'query-string';

function setQueryStringWithoutPageReload(qsValue) {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;

  window.history.pushState({ path: newurl }, '', newurl);
}

function setQueryStringValue(key, value, queryString = window.location.search) {
  const values = queryStringInstance.parse(queryString);
  const newQsValue = queryStringInstance.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
}

function getQueryStringValue(key, queryString = window.location.search) {
  const values = queryStringInstance.parse(queryString);
  return values[key];
}

export { setQueryStringWithoutPageReload, setQueryStringValue, getQueryStringValue };
