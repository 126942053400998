import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { TextFieldWithSubmitIcon } from '../src/ui_kit';
import { getQueryStringValue } from '../src/utilities/query_string_utilities';

function SearchBar() {
  const [searchTerms, setSearchTerms] = useState(getQueryStringValue('search_terms') || '');
  const [focusClass, setFocusClass] = useState('');

  function clear() {
    setSearchTerms('');
  }

  function search() {
    if (searchTerms.length > 0) {
      window.location.href = `/products?page=1&search_terms=${searchTerms}`;
    }
  }

  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      search();
    }
  }

  return (
    <div className={`component-search-bar ${focusClass}`}>
      <TextFieldWithSubmitIcon
        onFocus={() => setFocusClass('focused')}
        onBlur={() => setFocusClass('')}
        placeholder="Search Products"
        value={searchTerms}
        onChange={(e) => setSearchTerms(e.target.value)}
        onKeyDown={handleKeyDown}
        submitIcon="bx:bx-search"
        onSubmit={search}
        onClear={clear}
        className={focusClass}
      />
    </div>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('search-bar-mount-point');

  if (element) {
    ReactDOM.render(<SearchBar />, element);
  }
});
